
import { Component, Vue } from 'vue-property-decorator';
import { TOKEN_TAGS, TWITTER_TAGS, LINKS } from '@thxnetwork/public/utils/constants';
import BasePartners from '@thxnetwork/public/components/BasePartners.vue';
import BaseMetrics from '../components/BaseMetrics.vue';

@Component({
    metaInfo: {
        title: TOKEN_TAGS.TITLE,
        meta: [
            { name: 'title', content: TOKEN_TAGS.TITLE },
            { name: 'description', content: TOKEN_TAGS.DESCRIPTION },
            { name: 'keywords', content: TOKEN_TAGS.KEYWORDS },
            { name: 'twitter:card', content: TWITTER_TAGS.TWITTER_CARD },
            { name: 'twitter:site', content: TWITTER_TAGS.TWITTER_SITE },
            { name: 'twitter:creator', content: TWITTER_TAGS.TWITTER_CREATOR },
            { name: 'twitter:title', content: TOKEN_TAGS.TITLE },
            { name: 'twitter:description', content: TOKEN_TAGS.DESCRIPTION },
            { name: 'twitter:image:alt', content: TOKEN_TAGS.TITLE },
            { property: 'og:title', content: TOKEN_TAGS.TITLE },
            { property: 'og:description', content: TOKEN_TAGS.DESCRIPTION },
            { property: 'og:type', content: TOKEN_TAGS.OG_TYPE },
            { property: 'og:site_name', content: TOKEN_TAGS.OG_SITE_NAME },
            { property: 'og:url', content: TOKEN_TAGS.OG_URL },
        ],
        link: [{ rel: 'canonical', href: LINKS.TOKEN }],
    },
    components: {
        BaseMetrics,
        BasePartners,
    },
})
export default class Token extends Vue {}
